// // SLIDER

const initSlider = () => {
  const slider = new Swiper(".slider", {
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });

  const carouselMobileWrapper = document.querySelector('.carousel-mobile');
  if (carouselMobileWrapper !== null) {
    if (carouselMobileWrapper.dataset.slides > 1) {
      const carouselMobile = new Swiper(".carousel-mobile", {
        loop: true,
        grabCursor: true,
        slidesPerView: 1.2,
        autoplay: true,
        // freemode: true,
        centeredSlides: true,
        spaceBetween: 3.25,
      });
    }
  }

  const carouselWrapper = document.querySelector('.carousel');
  if (carouselWrapper !== null) {
    if (carouselWrapper.dataset.slides > 1) {
      const carousel = new Swiper(".carousel", {
        loop: true,
        grabCursor: true,
        slidesPerView: 3,
        autoplay: true,
        // freemode: true,
        spaceBetween: 1.2,
        centeredSlides: true,
        breakpoints: {
          768: {
            centeredSlides: false,
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          }
        }
      });
      console.log(carousel);
    }
  }
};
