

window.addEventListener("DOMContentLoaded", () => {
  document.documentElement.style.setProperty('--primary', php_to_js.themeColor);
  AOS.init({
    once: true,
    duration: 600,
  });
  initLazyVideos();
  initUl();
  initScrollUpDown();
  backTop();
  itemClickable('.item-clickable', '.item-clickable-link');
  toggleMenu();
  init_gform_values();
  initPictoPj();
  initAnimationLottie();
  initSnapScroll();
  changeMarkup();
  initImagesLoad();
  initArrowDown();
  initFilterItems();
  initSlider();
  initFilterMobile();
  initFilterClick();
  addInstagramOverlay();
  initGDPRColor();
  checkIOS();

  // initSafariFix();
});
