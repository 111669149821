// let prevScrollpos = window.pageYOffset;
window.addEventListener('scroll', () => {
  const currentScrollPos = window.pageYOffset;
  const shouldScroll = !document.getElementById('site-header').classList.contains('fixed-header');
  if (shouldScroll) {
    if (currentScrollPos < window.innerHeight) {
      document.body.classList.remove('scrolled');
    } else {
      document.body.classList.add('scrolled');
    }
  }
});

/**
 * Si ça marche pas, je vais pleurer.
 */
// const initSafariFix = () => {
//     self.scrollView.contentInsetAdjustmentBehavior = '.never'
//     self.contentInsetAdjustmentBehavior = '.never'
//     self.automaticallyAdjustsScrollViewInsets = false
//     automaticallyAdjustsScrollViewInsets = false
// }

const toggleMenu = () => {
  const menuIcon = document.querySelector('.toggle-nav');
  const menuModal = document.querySelector('.menu-modal');
  if (menuIcon !== null) {
    menuIcon.addEventListener('click', () => {
      menuIcon.classList.toggle('open');
      menuModal.classList.toggle('active');
      document.body.classList.toggle('showing-menu-modal');
    });
  }
}

const checkIOS = () => {
  const iOS = ( navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false );
  if(iOS){
    document.body.classList.add('is-ios');
  }
}

// const toogleHoverSubMenu = () => {
//   const itemHasSubMenu = document.querySelectorAll('.primary-menu-wrapper .primary-menu>li.menu-item-has-children');
//   const header = document.querySelector('#site-header');

//   if (itemHasSubMenu.length !== null) {
//     Array.from(itemHasSubMenu).forEach(item => {
//       item.addEventListener('mouseenter', () => {
//         header.classList.add('submenu--open');
//       });
//       item.addEventListener('mouseleave', () => {
//         setTimeout(() => {
//           header.classList.remove('submenu--open');
//         }, 300);
//       });
//     });
//   }
// };
